import React from 'react';
import styled from 'styled-components';
import { Paragraph } from '@lux/components-gomo';
import myInfoSrc from '../../../assets/images/myinfo.png';
import { EmphasizedInfo } from '../../../components/EmphasizedInfo';
import { RetrieveMyInfoButton } from '../../../components/common';
import { USER_DETAILS } from '../../../constants/page_content.json';

const StyledContainer = styled.div`
  margin: 24px 0 32px;
`;

const StyledInfoParagraph = styled(Paragraph)`
  margin: 32px 0 0;
`;

export const RetrieveMyInfoPanel = ({
  myInfoMode,
  onClearFormDetailsClick,
  onRetrieveMyInfoClick
}) => {
  if (myInfoMode) return null;

  return (
    <StyledContainer>
      {myInfoMode ? (
        <EmphasizedInfo
          headingImage={myInfoSrc}
          description={USER_DETAILS.MY_INFO.CLEAR_DETAILS_DESCRIPTION}
          linkText={'Clear details'}
          onLinkClick={onClearFormDetailsClick}
          highlightStyle={'secondary'}
        />
      ) : (
        <EmphasizedInfo
          headingImage={myInfoSrc}
          description={USER_DETAILS.MY_INFO.RETRIEVE_DETAILS_DESCRIPTION}
          ImageButton={RetrieveMyInfoButton}
          onButtonClick={onRetrieveMyInfoClick}
          buttonProps={{
            'data-testid': 'retrieve-myinfo-button',
            secondary: true
          }}
          highlightStyle={'secondary'}
        />
      )}
      <StyledInfoParagraph data-testid={'instructions'}>
        Regulations require filling in your personal details with Singpass. Make
        sure you’re using a device with a camera for verification.
      </StyledInfoParagraph>
    </StyledContainer>
  );
};
