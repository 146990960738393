import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Theme, Row, Column, Dropdown, TextField } from '@lux/components-gomo';
import { FormItem } from '../common';
import { noop } from '@lux/helpers';

const StyledRow = styled(Row)`
  margin-left: -4px;
  margin-right: -4px;
  display: flex;
  align-items: start;
`;

const StyledColumn = styled(Column)`
  padding-right: ${p => (p.paddingRight ? Theme.spacing.medium : '0')};
  padding-left: ${p => (p.paddingLeft ? Theme.spacing.medium : '0')};
  display: flex;
  justify-content: center;
`;

const InputAddress = props => {
  const {
    addressList,
    selectedAddressIdx,
    floorNumber,
    unitNumber,
    errors,
    noUnitDetails,
    address,
    onDropdownChange,
    onAddressSelectionChange
  } = props;

  const { unitNumbers = [] } = address;

  const normalisedFloors = unitNumbers.length
    ? unitNumbers.reduce((accum, current) => {
        const floor = current.split('-')[0].replace(/#/g, '');
        const unit = current.split('-')[1];
        if (!accum[floor]) {
          accum[floor] = [unit];
        } else {
          accum[floor] = [...accum[floor], unit];
        }
        return accum;
      }, {})
    : [];

  const floorOptions = Object.keys(normalisedFloors)
    .sort()
    .map(f => ({ value: f, text: f }));

  let unitOptions = [];
  if (normalisedFloors[floorNumber]) {
    unitOptions = normalisedFloors[floorNumber].map(u => ({
      value: u,
      text: u
    }));
  }

  return (
    <Fragment>
      {/*If only one address show as plain text*/}
      {addressList.length === 1 && (
        <FormItem label="Street Address" readOnly disabled>
          <TextField value={addressList[0].text} disabled />
        </FormItem>
      )}
      {/*Select address from drop down*/}
      {addressList.length > 1 && (
        <FormItem label="Street Address">
          <Dropdown
            id="street-address"
            placeholder="Select your address"
            selectedValue={selectedAddressIdx}
            options={addressList}
            onChange={e => onAddressSelectionChange(e)}
          />
        </FormItem>
      )}
      {unitNumbers.length > 0 && (
        <StyledRow>
          <StyledColumn sm={6} paddingRight>
            <FormItem
              data-testid="testSelectFloorNumber"
              label="Floor no."
              errorMessage={!noUnitDetails && errors['floorNumber']}
            >
              <Dropdown
                id="floorNumber"
                placeholder="Select a floor"
                selectedValue={floorNumber}
                options={floorOptions}
                onChange={e => onDropdownChange(e, 'floorNumber')}
              />
            </FormItem>
          </StyledColumn>
          <StyledColumn sm={6} paddingLeft>
            <FormItem
              data-testid="testSelectUnitNumber"
              label="Unit no."
              errorMessage={!noUnitDetails && errors['unitNumber']}
            >
              <Dropdown
                id="unitNumber"
                placeholder="Select a unit"
                selectedValue={unitNumber}
                options={unitOptions}
                onChange={e => onDropdownChange(e, 'unitNumber')}
              />
            </FormItem>
          </StyledColumn>
        </StyledRow>
      )}
    </Fragment>
  );
};

InputAddress.defaultProps = {
  addressList: [],
  selectedAddressIdx: null,
  floorNumber: '',
  unitNumber: '',
  errors: {},
  address: {},
  onDropdownChange: noop
};

InputAddress.propTypes = {
  /* Address List */
  addressList: PropTypes.array,
  /** Floor number */
  floorNumber: PropTypes.string,
  /** Unit number */
  unitNumber: PropTypes.string,
  /** Error object */
  errors: PropTypes.shape({
    floorNumber: PropTypes.string,
    unitNumber: PropTypes.string
  }),
  /** Address object */
  address: PropTypes.shape({
    blockNumber: PropTypes.string,
    street: PropTypes.string
  }),
  /** onDropdownChange event handler */
  onDropdownChange: PropTypes.func
};

export default InputAddress;
