import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Column,
  Dropdown,
  IDTextField,
  ImageUpload,
  Row,
  TextField,
  Theme
} from '@lux/components-gomo';
import { noop } from '@lux/helpers';
import { BinarySelector } from '@dls/web';

import { FormItem } from '../common';

import TITLE_OPTIONS from '../../constants/customer_titles.json';
import GENDER_OPTIONS from '../../constants/genders.json';
import {
  ID_TYPE_OPTIONS,
  ID_PREFIX_EKYC_DETAILS,
  ID_PREFIX_DETAILS,
  PREFIX_OPTIONS,
  PREFIX_OPTIONS_M,
  LTVP_CARD_TYPES
} from '../../constants/identification_types.json';
import NotEligibleIconSrc from '../../assets/images/attention.png';
import { capitalize } from '../../helpers/formatter';
import {
  incompletePassCheck,
  inEligiblePassCheck
} from '../../helpers/myInfoValidator';
import { INELIGIBLE_ERRORS } from '../../constants/response_types.json';
import userDetailsFormFields from '../../constants/userDetailsFormFields.json';
import { isMID } from '../../helpers/validation';
import { DigitalPassVerification } from './DigitalPassVerification';
import {
  isEsim,
  shouldShowLtvpCardTypeSelectionSection,
  shouldShowPhysicalCardUploadSection,
  shouldShowLtvpDigitalPassSection
} from '../../helpers/userDetailsForm';
import { getNationalities } from '../../helpers/identification';
import { TitleSection } from './TitleSection';
import { USER_DETAILS } from '../../constants/page_content.json';
import RetrievePostalCode from '../RetrievePostalCode';
import InputAddress from '../InputAddress';

const StyledColumn = styled(Column)`
  padding-right: ${p => (p.paddingRight ? Theme.spacing.small : '0')};
  padding-left: ${p => (p.paddingLeft ? Theme.spacing.small : '0')};
`;

const StyledRow = styled(Row)`
  width: 100%;
`;

const NotEligiblePass = styled.div`
  display: flex;
  align-items: center;
  font-family: ${Theme.fonts.families.Poppins};
  border-radius: 8px;
  background-color: #ffe8ec;
  padding: 16px;
  margin: 24px 0;
`;
const NotEligibleIcon = styled.img`
  margin-right: 16px;
  width: 24px;
`;

const NotEligiblePassDescription = styled.div`
  font-weight: 600;
  color: ${Theme.colours.gomo_black_33};
`;
const NameIndicate = styled.div`
  color: ${Theme.colours.gomo_black_33};
  font-family: ${Theme.fonts.families.Poppins};
  padding-bottom: 24px;
`;

export const NotEligiblePassLink = styled.span`
  font-weight: 600;
  cursor: pointer;
  color: ${Theme.colours.gomo_cyan};
`;

const StyledBinarySelectorWrapper = styled.div`
  && > div > div > label {
    margin: 0px;

    > span {
      font-family: ${Theme.fonts.families.Poppins};
      font-weight: ${Theme.fonts.weight.regular};
      font-size: ${Theme.fonts.sizes.regular};
    }
  }
  width: 100%;
`;

const UserDetailsFields = props => {
  const {
    firstName,
    lastName,
    title,
    gender,
    email,
    confirmEmail,
    contactNo,
    dateOfBirth,
    idPrefix,
    idNo,
    idType,
    ltvpCardType,
    nationality,
    deliveryAddress,
    postalCode,
    retrieveAddressLoading,
    formattedBillingAddressList,
    selectedAddressIdx,
    addressToRender,
    floorNumber,
    unitNumber,
    errors,
    uploadedImages,
    onDropdownChange,
    onTextChange,
    onTextBlur,
    onImageUpload,
    onRadioInputChange,
    onRetrieveAddressClick,
    onAddressSelectionChange,
    onRetrieveMyInfo,
    isSelfCollection,
    disablePaste,
    disabled,
    myinfoMode,
    selectedSimType,
    passStatus,
    passExpiryDate,
    clearFormDetails,
    mIDSupport
  } = props;

  //Check if M ID Support is enabled
  const noMPassSupport = !mIDSupport && isMID(idPrefix) && myinfoMode;

  const isIncompletePassCheck = incompletePassCheck({
    myinfoMode,
    idType,
    idNo,
    nationality,
    passStatus,
    passExpiryDate,
    idPrefix,
    enableMID: mIDSupport,
    dateOfBirth,
    firstName
  });

  //Serve M support if enabled or if coming from MyInfo
  const idPrefixOption =
    myinfoMode || mIDSupport ? PREFIX_OPTIONS_M : PREFIX_OPTIONS;

  const idTypeOptions = ID_TYPE_OPTIONS.filter(t => {
    let idPrefixOptions =
      myinfoMode === 'workPass' ? ID_PREFIX_EKYC_DETAILS : ID_PREFIX_DETAILS;

    return idPrefixOptions[idPrefix].idTypeOptions.includes(t.value);
  });

  const showLtvpCardTypeSelectionSection = shouldShowLtvpCardTypeSelectionSection(
    { myinfoMode, idType }
  );
  const showLtvpDigitalPassSection = shouldShowLtvpDigitalPassSection({
    myinfoMode,
    idType,
    ltvpCardType
  });
  const showPhsyicalCardUploadSection = shouldShowPhysicalCardUploadSection({
    myinfoMode,
    idType,
    ltvpCardType
  });
  const notEligiblePassCheck =
    myinfoMode &&
    inEligiblePassCheck({
      myinfoMode,
      passStatus,
      passExpiryDate,
      idType
    });

  const nationalities = getNationalities(idPrefix);

  const isEsimSelected = isEsim(selectedSimType);

  const findOutMore = () => {
    window.open('https://gomo.sg/faq');
  };

  const ineligibleMessage = () => {
    let errorMsg = {};

    //Check if M series is supported
    if (noMPassSupport && isMID(idPrefix)) {
      errorMsg = INELIGIBLE_ERRORS.UNSUPPORTED_M_SERIES;
    } else {
      errorMsg = INELIGIBLE_ERRORS.INELIGIBLE;
    }

    return (
      <NotEligiblePass>
        <NotEligibleIcon src={NotEligibleIconSrc} />
        <div>
          <Fragment>
            <NotEligiblePassDescription>
              {errorMsg.TITLE}
            </NotEligiblePassDescription>
            <div data-testid="ineligible-msg">{errorMsg.CONTENT}</div>
            {errorMsg.SHOW_CLEARLINK && (
              <NotEligiblePassLink onClick={clearFormDetails}>
                {errorMsg.SHOW_CLEARTEXT}
              </NotEligiblePassLink>
            )}
          </Fragment>
        </div>
      </NotEligiblePass>
    );
  };

  return (
    <Fragment>
      {isIncompletePassCheck && ineligibleMessage()}

      <TitleSection
        title={USER_DETAILS.SECTION_HEADING.PERSONAL_DETAILS}
        description={"We'll use this for all communication purposes."}
      />

      {disabled ? (
        <FormItem
          label="Full Name"
          errorMessage={errors['firstName']}
          disabled={disabled}
        >
          <TextField
            data-testid="testFirstName"
            id="firstName"
            placeholder="Eg. John Ber Pian"
            value={capitalize(firstName)}
            onBlur={e => onTextBlur(e, 'firstName')}
            onChange={e => onTextChange(e, 'firstName')}
            disabled={disabled}
          />
        </FormItem>
      ) : (
        <Row>
          <StyledColumn sm={6} paddingRight>
            <FormItem
              label="First Name"
              hintMessage=""
              errorMessage={errors['firstName']}
            >
              <TextField
                data-testid="testFirstName"
                id="firstName"
                placeholder="Eg. John Ber Pian"
                value={firstName}
                onBlur={e => onTextBlur(e, 'firstName')}
                onChange={e => onTextChange(e, 'firstName')}
                disabled={disabled}
              />
            </FormItem>
          </StyledColumn>
          <StyledColumn sm={6} paddingLeft>
            <FormItem label="Last Name" errorMessage={errors['lastName']}>
              <TextField
                data-testid="testLastName"
                id="lastName"
                placeholder="Eg. Sim"
                value={lastName}
                onBlur={e => onTextBlur(e, 'lastName')}
                onChange={e => onTextChange(e, 'lastName')}
                disabled={disabled}
              />
            </FormItem>
          </StyledColumn>
          <NameIndicate>
            As shown on your ID or delivery may be rejected
          </NameIndicate>
        </Row>
      )}
      <Row>
        <StyledColumn sm={6} paddingRight>
          <FormItem
            label="Title"
            errorMessage={errors['title']}
            data-testid="testTitle"
          >
            <Dropdown
              id="title"
              placeholder="Select a title"
              selectedValue={title}
              options={TITLE_OPTIONS}
              onChange={e => onDropdownChange(e, 'title')}
            />
          </FormItem>
        </StyledColumn>
        <StyledColumn sm={6} paddingLeft>
          <FormItem
            label="Gender"
            errorMessage={errors['gender']}
            data-testid="testGender"
          >
            <Dropdown
              id="gender"
              placeholder="Select a gender"
              selectedValue={gender}
              options={GENDER_OPTIONS}
              onChange={e => onDropdownChange(e, 'gender')}
            />
          </FormItem>
        </StyledColumn>
      </Row>
      <FormItem
        label="Date of Birth"
        errorMessage={errors['dateOfBirth']}
        disabled={disabled}
      >
        <TextField
          id="dateOfBirth"
          placeholder="DD/MM/YYYY"
          value={dateOfBirth}
          onBlur={e => onTextBlur(e, 'dateOfBirth')}
          onChange={e => onTextChange(e, 'dateOfBirth')}
          disabled={disabled}
        />
      </FormItem>

      {notEligiblePassCheck && (
        <NotEligiblePass>
          <NotEligibleIcon src={NotEligibleIconSrc} />
          <div>
            <NotEligiblePassDescription>
              This ID is not eligible for signup
            </NotEligiblePassDescription>
            <NotEligiblePassLink
              onClick={findOutMore}
              data-testid="notEligibleUrl"
            >
              Find out more under Sign up FAQ
            </NotEligiblePassLink>
          </div>
        </NotEligiblePass>
      )}
      <FormItem
        data-testid="testIDNumber"
        label="NRIC/FIN"
        errorMessage={errors['idNo']}
        disabled={disabled}
      >
        <IDTextField
          id="idNo"
          prefixValue={idPrefix}
          prefixOptions={idPrefixOption}
          onPrefixChange={e => onTextChange(e, 'idPrefix')}
          onIDChange={e => onTextChange(e, 'idNo')}
          onIDBlur={e => onTextBlur(e, 'idNo')}
          idValue={idNo}
          disabled={disabled}
        />
      </FormItem>
      {myinfoMode === 'workPass' && (
        <Row>
          <StyledColumn sm={6} paddingRight>
            <FormItem
              label="ID status"
              data-testid="testIDstatus"
              disabled={disabled}
              errorMessage={errors['passStatus']}
            >
              <TextField
                id="ID status"
                defaultValue={passStatus}
                disabled={disabled}
              />
            </FormItem>
          </StyledColumn>
          <StyledColumn sm={6} paddingLeft>
            <FormItem
              label="ID expiry date"
              data-testid="testIDexpiry"
              disabled={disabled}
              errorMessage={errors['passExpiryDate']}
            >
              <TextField
                id="IDExpiryDate"
                defaultValue={passExpiryDate}
                disabled={disabled}
              />
            </FormItem>
          </StyledColumn>
        </Row>
      )}
      <FormItem
        label="Nationality"
        errorMessage={errors['nationality']}
        data-testid="testNationality"
        disabled={disabled}
      >
        <Dropdown
          id="nationality"
          placeholder="Select a nationality"
          selectedValue={nationality}
          options={nationalities}
          onChange={e => onDropdownChange(e, 'nationality')}
          disabled={disabled}
        />
      </FormItem>
      <FormItem
        label="ID Type"
        errorMessage={errors['idType']}
        data-testid="testIDType"
        disabled={disabled}
      >
        <Dropdown
          id="idType"
          placeholder="Select an ID type"
          selectedValue={idType}
          options={idTypeOptions}
          onChange={e => onDropdownChange(e, 'idType')}
          disabled={disabled}
        />
      </FormItem>
      {showLtvpCardTypeSelectionSection && (
        <FormItem
          readOnly
          flexHeight
          data-testid="ltvp-card-type-selection-section"
        >
          <StyledBinarySelectorWrapper>
            <BinarySelector
              name="ltvpCardTypeBinarySelector"
              value={ltvpCardType}
              firstOption={{
                label: LTVP_CARD_TYPES.PHYSICAL.text,
                value: LTVP_CARD_TYPES.PHYSICAL.value
              }}
              secondOption={{
                label: LTVP_CARD_TYPES.DIGITAL.text,
                value: LTVP_CARD_TYPES.DIGITAL.value
              }}
              onChange={e =>
                onRadioInputChange(e, userDetailsFormFields.ltvpCardType)
              }
            />
          </StyledBinarySelectorWrapper>
        </FormItem>
      )}
      {showPhsyicalCardUploadSection && (
        <div id="images">
          <FormItem
            label="Snap / Upload ID"
            readOnly
            flexHeight
            errorMessage={errors['images']}
            hintMessage="Upload your original ID in colour with a maximum file size of 10MB (JPEG/PNG format)"
            data-testid="physical-card-upload-section"
          >
            <StyledRow>
              <StyledColumn sm={6} paddingRight data-testid="testUploadFrontID">
                <ImageUpload
                  id="front"
                  label="Front of ID"
                  previewImgSrc={uploadedImages['front'].previewImgSrc}
                  placeholder="Front"
                  errorMessage={uploadedImages['front'].error}
                  loading={uploadedImages['front'].loading}
                  onChange={e => onImageUpload(e, 'front')}
                  height={120}
                />
              </StyledColumn>
              <StyledColumn sm={6} paddingLeft data-testid="testUploadBackID">
                <ImageUpload
                  id="back"
                  label="Back of ID"
                  previewImgSrc={uploadedImages['back'].previewImgSrc}
                  placeholder="Back"
                  errorMessage={uploadedImages['back'].error}
                  loading={uploadedImages['back'].loading}
                  onChange={e => onImageUpload(e, 'back')}
                  height={120}
                />
              </StyledColumn>
            </StyledRow>
          </FormItem>
        </div>
      )}
      {showLtvpDigitalPassSection && (
        <FormItem
          readOnly
          flexHeight
          errorMessage={errors['ltvpDigitalPass']}
          data-testid="ltvp-digital-pass-section-form-item"
        >
          <DigitalPassVerification onRetrieveMyInfo={onRetrieveMyInfo} />
        </FormItem>
      )}

      <RetrievePostalCode
        addressLabel={'Billing address'}
        postalCode={postalCode}
        error={errors['postalCode']}
        retrieveAddressLoading={retrieveAddressLoading}
        containerStyle={{
          top: 0
        }}
        onTextBlur={onTextBlur}
        onTextChange={onTextChange}
        onRetrieveAddressClick={onRetrieveAddressClick}
      />
      {formattedBillingAddressList && (
        <InputAddress
          addressList={formattedBillingAddressList}
          selectedAddressIdx={selectedAddressIdx}
          address={addressToRender}
          floorNumber={floorNumber}
          unitNumber={unitNumber}
          errors={errors}
          onDropdownChange={onDropdownChange}
          onAddressSelectionChange={onAddressSelectionChange}
        />
      )}

      <TitleSection
        title={USER_DETAILS.SECTION_HEADING.CONTACT_DETAILS}
        description={
          isEsimSelected
            ? USER_DETAILS.SECTION_DESCRIPTION.CONTACT_DETAILS_ESIM
            : USER_DETAILS.SECTION_DESCRIPTION.CONTACT_DETAILS_PSIM
        }
      />

      <FormItem label="Email" errorMessage={errors['email']}>
        <TextField
          data-testid="testEmail"
          id="email"
          placeholder="Eg. simple@isagoodstart.com"
          value={email}
          onBlur={e => onTextBlur(e, 'email')}
          onChange={e => onTextChange(e, 'email')}
        />
      </FormItem>
      <FormItem label="Confirm email" errorMessage={errors['confirmEmail']}>
        <TextField
          data-testid="testConfirmEmail"
          id="confirmEmail"
          placeholder=""
          value={confirmEmail}
          onBlur={e => onTextBlur(e, 'confirmEmail')}
          onChange={e => onTextChange(e, 'confirmEmail')}
          onPaste={e => disablePaste(e)}
          autoComplete={'off'}
        />
      </FormItem>
      <FormItem label="Contact Number" errorMessage={errors['contactNo']}>
        <TextField
          data-testid="testContactNO"
          id="contactNo"
          type="tel"
          placeholder="Eg. 8140 7734"
          value={contactNo}
          onBlur={e => onTextBlur(e, 'contactNo')}
          onChange={e => onTextChange(e, 'contactNo')}
          maxLength={8}
        />
      </FormItem>
    </Fragment>
  );
};

UserDetailsFields.defaultProps = {
  firstName: '',
  lastName: '',
  title: '',
  gender: '',
  email: '',
  contactNo: '',
  dateOfBirth: '',
  idPrefix: 's',
  idNo: '',
  idType: '',
  errors: {},
  uploadedImages: {
    front: {
      error: '',
      loading: false
    },
    back: {
      error: '',
      loading: false
    }
  },
  onDropdownChange: noop,
  onTextChange: noop,
  onTextBlur: noop,
  onImageUpload: noop,
  isSelfCollection: false,
  disablePaste: noop
};

UserDetailsFields.propTypes = {
  /** First Name */
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  /** Title */
  title: PropTypes.string,
  /** Gender */
  gender: PropTypes.string,
  /** Email */
  email: PropTypes.string,
  /** Contact Number */
  contactNo: PropTypes.string,
  /** Date of Birth */
  dateOfBirth: PropTypes.string,
  /** First char of ID */
  idPrefix: PropTypes.string,
  /** ID number */
  idNo: PropTypes.string,
  /** ID type */
  idType: PropTypes.string,
  /** LTVP Card Type */
  ltvpCardType: PropTypes.oneOf([
    LTVP_CARD_TYPES.DIGITAL.value,
    LTVP_CARD_TYPES.PHYSICAL.value
  ]),
  /** Selected sim type value */
  selectedSimType: PropTypes.string,
  /** Error object. key is key of field, value is error message*/
  errors: PropTypes.objectOf(PropTypes.string),
  /** Uploaded images */
  uploadedImages: PropTypes.shape({
    front: PropTypes.shape({
      loading: PropTypes.bool,
      error: PropTypes.string
    }),
    back: PropTypes.shape({
      loading: PropTypes.bool,
      error: PropTypes.string
    })
  }),
  /** Drodown change handler */
  onDropdownChange: PropTypes.func,
  /** Text change handler */
  onTextChange: PropTypes.func,
  /** Text blur handler */
  onTextBlur: PropTypes.func,
  /** Image upload handler */
  onImageUpload: PropTypes.func,
  /** Radio input change handler */
  onRadioInputChange: PropTypes.func,
  /** Notify when needed to retrieve my info */
  onRetrieveMyInfo: PropTypes.func,
  /** Is SelfCollection - Billing Address should be mandatory */
  isSelfCollection: PropTypes.bool,
  disablePaste: PropTypes.func,
  clearFormDetails: PropTypes.func
};

export default UserDetailsFields;
