import React from 'react';
import { Helmet } from 'react-helmet';
import InputUserDetails from '../containers/InputUserDetails';
import withLayout from '../components/withLayout';
import { ThemeProvider } from '@dls/web';

import { PRODUCT_NAME, USER_DETAILS } from '../constants/page_content.json';

const InputUserDetailsPage = ({ location }) => (
  <ThemeProvider brand="advance">
    <Helmet defaultTitle={PRODUCT_NAME} titleTemplate="%s">
      <title>{USER_DETAILS.TITLE}</title>
    </Helmet>
    <InputUserDetails location={location} />
  </ThemeProvider>
);

export default withLayout(InputUserDetailsPage);
