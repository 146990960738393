import React, { Component } from 'react';
import { connect } from 'react-redux';
import { navigate } from 'gatsby';
import { Heading, Theme, Paragraph } from '@lux/components-gomo';
import { Row, Column } from '@dls/web';
import { format } from 'date-fns';
import qs from 'query-string';
import styled from 'styled-components';

import {
  checkName,
  checkEmail,
  checkImages,
  checkIsEmpty,
  isMID,
  checkLtvpDigitalPass
} from '../../helpers/validation';
import {
  checkNRIC,
  isCitizenPrefix,
  removePrefix
} from '../../helpers/identification';
import {
  strToDate,
  checkDob,
  preFormatDate,
  isDate
} from '../../helpers/datetime';
import { checkAddress, checkPostalCode } from '../../helpers/address';
import {
  myInfoValidator,
  incompletePassCheck,
  inEligiblePassCheck
} from '../../helpers/myInfoValidator';
import { getState } from '../../helpers/persist-state';
import { checkMobileNumberOnly } from '../../helpers/mobileNumber';
import { isSwitchEnabled } from '../../helpers/switch';
import actions from '../../actions';

import UserDetailsFields from '../../components/UserDetailsFields';
import NavControls from '../../components/NavControls';
import withApiFailureModal from '../withApiFailureModal';
import withRedirect from '../withRedirect';
import {
  PASSTYPES,
  LTVP_CARD_TYPES
} from '../../constants/identification_types.json';
import userDetailsFormFields from '../../constants/userDetailsFormFields.json';
import { NUMBER_SELECTION_PAGE } from '../../constants/links.json';
import { CTA_TEXT } from '../../constants/page_content.json';

import {
  createLoadingSelector,
  createErrorMessageSelector
} from '../../selectors';
import { ENABLE_M_ID_SUPPORT } from '../../constants/app_configuration';
import {
  USER_DETAILS_FORM_ERRORS as FORM_ERRORS,
  shouldShowUserDetailsForm
} from '../../helpers/userDetailsForm';
import { RetrieveMyInfoPanel } from './RetrieveMyInfoPanel';
import { UserDetailsErrorModal } from './UserDetailsErrorModal';
import {
  GTM_DATALAYER_EVENTS,
  GTM_EVENT_ACTIONS,
  GTM_EVENT_CATEGORIES
} from '../../constants/gtmDataLayerEvents';

const retrieveAddressSelector = createLoadingSelector(['RETRIEVE_ADDRESS']);
const retrieveAddressErrorSelector = createErrorMessageSelector([
  'RETRIEVE_ADDRESS'
]);
const CITIZEN = PASSTYPES.CITIZEN;
const WORK_PASS = PASSTYPES.WORKPASS;
const hasMSupport = isSwitchEnabled(ENABLE_M_ID_SUPPORT);

const StyledRow = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${p => '#fbebf2d9'};
  padding: ${p => '12px'};
  border-radius: 8px;
  margin: ${p => '12px 0'};
  ${Theme.media.md`
    padding: ${p => '12px'};
    margin: ${p => '12px 0'};
  `};
`;

export class InputUserDetails extends Component {
  state = {
    firstName: this.props.userDetails.firstName,
    lastName: this.props.userDetails.lastName,
    title: this.props.userDetails.title,
    gender: this.props.userDetails.gender,
    email: this.props.userDetails.email,
    confirmEmail: this.props.userDetails.confirmEmail,
    contactNo: this.props.userDetails.contactNo,
    dateOfBirth: this.props.userDetails.dateOfBirth
      ? format(preFormatDate(this.props.userDetails.dateOfBirth), 'dd/MM/yyyy')
      : '',
    idPrefix: this.props.userDetails.idNo
      ? this.props.userDetails.idNo.charAt(0).toLowerCase()
      : 's',
    idNo: removePrefix(this.props.userDetails.idNo, hasMSupport) || '',
    idType: this.props.userDetails.idType,
    nationality: this.props.userDetails.nationality,
    frontIdImage: this.props.userDetails.frontIdImage,
    backIdImage: this.props.userDetails.backIdImage,
    postalCode: this.props.userDetails.address.postalCode,
    retrievedPostalCode: this.props.userDetails.address.postalCode,
    floorNumber: this.props.userDetails.address.floorNumber,
    unitNumber: this.props.userDetails.address.unitNumber,
    addressList: [],
    selectedAddressIdx: null,
    errors: {},
    code: null,
    dobNricError: false,
    ltvpCardType: LTVP_CARD_TYPES.PHYSICAL.value,
    myInfoDataRetrieved: false
  };

  componentDidMount() {
    let { code, error } = qs.parse(this.props.location.search || {});
    let customerData = JSON.parse(localStorage.getItem('customerData'));
    let queryString = getState('queryString');
    if (
      (code || error) &&
      customerData &&
      customerData.selectedNumber.oauthToken
    ) {
      let {
        selectedNumber,
        auth,
        deliveryDates,
        deliveryDetails,
        retrievedAddress,
        selfCollection,
        simTypeDetails,
        apigeeToken
      } = customerData;

      if (queryString) {
        //Rehydrate extracted promocode from first entry
        this.props.rehydrateQueryString(queryString);
      }

      // dispatch data to reducer when user comes back from myinfo
      this.props.rehydrateSelectedNumber(selectedNumber);
      this.props.setAuthToken(auth.token);
      this.props.rehydrateDeliveryDates(deliveryDates);
      this.props.setDeliveryDetails(deliveryDetails);
      this.props.rehydrateRetrieveAddress(retrievedAddress);
      this.props.rehydrateSelfCollect(selfCollection);
      this.props.rehydrateSimTypeDetails(simTypeDetails);
      this.props.rehydrateApigeeToken(apigeeToken);

      // call get user's data from myinfo with code
      window.history.replaceState(null, null, window.location.pathname);
      if (code) {
        this.props.getMyinfoData({
          code,
          oauthToken: selectedNumber.oauthToken,
          challengeCode: auth.token,
          mobileNumber: selectedNumber.number
        });
        this.setState({ code });
      }
    } else if (!this.props.authToken) {
      navigate('/select-number/');
    }
    const { userDetails, billingAddressList } = this.props;
    let selectedAddressIdx = null;
    //If user is navigating back from delivery details page, prepopulate the street address dropdown
    if (
      userDetails &&
      userDetails.address.street &&
      billingAddressList &&
      billingAddressList.length > 0
    ) {
      selectedAddressIdx = billingAddressList.findIndex(
        address =>
          address.blockNumber === userDetails.address.blockNumber &&
          address.street === userDetails.address.street
      );
    }
    this.setState({
      selectedAddressIdx: selectedAddressIdx >= 0 ? selectedAddressIdx : null
    });
  }

  componentDidUpdate(prevProps) {
    if (!this.props.authToken) {
      navigate('/select-number/');
    }
    // Set default billing address to first one if there is only one address
    if (
      !prevProps.billingAddressList &&
      this.props.billingAddressList &&
      this.props.billingAddressList.length === 1
    ) {
      this.setState({
        selectedAddressIdx: 0
      });
    }

    if (prevProps.uploadedImages !== this.props.uploadedImages) {
      const errors = { ...this.state.errors };

      errors.images = checkImages({
        images: this.props.uploadedImages,
        myinfoMode: this.props.myinfoMode,
        idType: this.state.idType,
        ltvpCardType: this.state.ltvpCardType
      });
      this.setState({ errors });
    }
    if (this.state.code) {
      if (
        JSON.stringify(prevProps.userDetails) !==
        JSON.stringify(this.props.userDetails)
      ) {
        let myinfoError = {};
        if (this.props.userDetails.myinfoMode) {
          //Validate myinfo - show error message
          myinfoError = myInfoValidator({
            firstName: this.props.userDetails.firstName,
            dateOfBirth: this.props.userDetails.dateOfBirth,
            idPrefix: this.props.userDetails.idNo,
            idNo: this.props.userDetails.idNo,
            idType: this.props.userDetails.idType,
            nationality: this.props.userDetails.nationality,
            passStatus: this.props.userDetails.passStatus,
            passExpiryDate: this.props.userDetails.passExpiryDate
          });
        }

        this.setState({
          firstName: this.props.userDetails.firstName,
          title: this.props.userDetails.title,
          gender: this.props.userDetails.gender,
          email: this.props.userDetails.email,
          confirmEmail: this.props.userDetails.confirmEmail,
          contactNo: this.props.userDetails.contactNo,
          dateOfBirth: isDate(this.props.userDetails.dateOfBirth)
            ? format(new Date(this.props.userDetails.dateOfBirth), 'dd/MM/yyyy')
            : '',
          idPrefix: this.props.userDetails.idNo
            ? this.props.userDetails.idNo.charAt(0).toLowerCase()
            : 's',
          idNo: removePrefix(this.props.userDetails.idNo, true) || '',
          idType: this.props.userDetails.idType,
          nationality: this.props.userDetails.nationality,
          code: null,
          errors: myinfoError,
          myInfoDataRetrieved: true
        });
      }
    }
  }

  checkForm = () => {
    let errors = {};
    const ltvpDigitalPassError = checkLtvpDigitalPass({
      myinfoMode: this.props.myinfoMode,
      idType: this.state.idType,
      ltvpCardType: this.state.ltvpCardType
    });

    if (ltvpDigitalPassError) {
      errors = { ltvpDigitalPass: ltvpDigitalPassError };
    } else {
      errors = {
        firstName: this.props.myinfoMode
          ? ''
          : checkName(this.state.firstName, 'first name'),
        lastName: this.props.myinfoMode
          ? ''
          : checkName(this.state.lastName, 'last name'),
        title: checkIsEmpty(this.state.title, 'title'),
        gender: checkIsEmpty(this.state.gender, 'gender'),
        email: checkEmail(this.state.email),
        confirmEmail:
          this.state.email.toUpperCase() !=
          this.state.confirmEmail.toUpperCase()
            ? "Email address don't match."
            : '',
        contactNo: checkMobileNumberOnly(
          this.state.contactNo,
          'Please enter your contact number.'
        ),
        dateOfBirth: checkDob(this.state.dateOfBirth),
        idNo: checkNRIC(this.state.idNo, this.state.idPrefix),
        idType: checkIsEmpty(this.state.idType, 'ID type'),
        nationality: checkIsEmpty(this.state.nationality, 'nationality'),
        images: checkImages({
          images: this.props.uploadedImages,
          myinfoMode: this.props.myinfoMode,
          idType: this.state.idType,
          ltvpCardType: this.state.ltvpCardType
        }),
        ltvpDigitalPass: ''
      };
    }

    errors.postalCode = checkPostalCode(this.state.postalCode);
    const addressToCheck = this.determineNewAddress();
    if (addressToCheck.unitNumbers) {
      errors.floorNumber = checkAddress(this.state.floorNumber, 'floorNumber');
      errors.unitNumber = checkAddress(this.state.unitNumber, 'unitNumber');
    }

    return errors;
  };

  getAddressList = () => {
    let addressList = [];
    const { billingAddressList } = this.props;

    if (billingAddressList && billingAddressList.length > 0) {
      addressList = billingAddressList.map((address, idx) => {
        return {
          text: address.blockNumber + ' ' + address.street,
          value: idx
        };
      });
    }
    return addressList;
  };

  determineNewAddress = () => {
    let address = {};

    const {
      retrievedPostalCode,
      floorNumber,
      unitNumber,
      selectedAddressIdx
    } = this.state;

    const { billingAddressList, userDetails, hasSubmittedOnce } = this.props;

    if (billingAddressList && selectedAddressIdx !== null) {
      // user has retrieved addresss using postal code
      address = {
        blk: billingAddressList[selectedAddressIdx].blk,
        blockNumber: billingAddressList[selectedAddressIdx].blockNumber,
        street: billingAddressList[selectedAddressIdx].street,
        unitNumbers: billingAddressList[selectedAddressIdx].unitNumbers,
        buildClass: billingAddressList[selectedAddressIdx].buildClass,
        postalCode: retrievedPostalCode,
        floorNumber,
        unitNumber
      };
    } else if (hasSubmittedOnce) {
      // user has submitted once and come back to the page,
      // so use submitted address if they haven't retrieved new one
      address = {
        blk: userDetails.address.blk,
        blockNumber: userDetails.address.blockNumber,
        street: userDetails.address.street,
        unitNumbers: userDetails.address.unitNumbers,
        postalCode: userDetails.address.postalCode,
        buildClass: userDetails.address.buildClass,
        floorNumber,
        unitNumber
      };
    }

    return address;
  };

  determineUserDetails = () => {
    const id = (this.state.idPrefix + this.state.idNo).toUpperCase();
    const dateObj = strToDate(this.state.dateOfBirth);
    const formattedDob = format(dateObj, 'dd-MMM-yyyy');

    return {
      firstName: this.state.firstName.replace(/ +(?= )/g, '').trim(),
      lastName: this.state.lastName.replace(/ +(?= )/g, '').trim(),
      title: this.state.title,
      gender: this.state.gender,
      email: this.state.email,
      confirmEmail: this.state.confirmEmail,
      contactNo: this.state.contactNo,
      dateOfBirth: formattedDob,
      originDateOfBirth: this.props.userDetails.originDateOfBirth,
      idNo: id,
      idType: this.state.idType,
      nationality: this.state.nationality,
      frontIdImage: this.props.uploadedImages.front.imageId,
      backIdImage: this.props.uploadedImages.back.imageId,
      myinfoMode: this.props.userDetails.myinfoMode,
      referenceNo: this.props.userDetails.referenceNo,
      passType: this.props.userDetails.passType,
      passStatus: this.props.userDetails.passStatus,
      passExpiryDate: this.props.userDetails.passExpiryDate
    };
  };

  handleTextBlur = (e, key) => {
    const { isSelfCollection } = this.props;
    const text = e.target.value;
    const errors = { ...this.state.errors };

    const checkHandlerMapping = {
      firstName: checkName,
      lastName: checkName,
      email: checkEmail,
      confirmEmail: checkEmail,
      contactNo: checkMobileNumberOnly,
      idNo: checkNRIC,
      postalCode: checkPostalCode,
      dateOfBirth: checkDob
    };

    const secondParam = {
      idNo: this.state.idPrefix,
      firstName: 'first name',
      lastName: 'last name'
    };

    const error = checkHandlerMapping[key]
      ? checkHandlerMapping[key](text, secondParam[key] || '')
      : '';
    if (error !== '') {
      errors[key] = error;
    } else {
      delete errors[key];
    }

    this.setState({ errors });
  };

  handleNextButtonClick = () => {
    if (
      this.props.userDetails.passStatus?.toUpperCase() !== 'LIVE' &&
      this.props.userDetails.myinfoMode === WORK_PASS
    )
      return;

    const errors = this.checkForm();
    const isValidForm =
      Object.values(errors).filter(e => e.length !== 0).length === 0;

    if (isValidForm) {
      const userDetails = this.determineUserDetails();
      userDetails.address = this.determineNewAddress();
      const { serviceNumber, authToken } = this.props;

      // check DOB
      let twoDigitNric = parseInt(this.state.idNo.trim().substr(0, 2));
      let twoDigitYear = parseInt(this.state.dateOfBirth.trim().slice(-2));
      let fourDigitYear = parseInt(this.state.dateOfBirth.trim().slice(-4));
      if (this.state.idPrefix === 't' && fourDigitYear < 2000) {
        return this.setState({ dobNricError: true });
      }
      if (
        this.state.idPrefix === 't' &&
        fourDigitYear >= 2000 &&
        twoDigitNric !== twoDigitYear
      ) {
        return this.setState({ dobNricError: true });
      }

      if (this.state.idPrefix === 's') {
        if (
          twoDigitNric > 68 &&
          twoDigitNric < 99 &&
          (fourDigitYear < 1968 || fourDigitYear > 1999)
        )
          return this.setState({ dobNricError: true });

        if (twoDigitNric >= 68 && twoDigitNric !== twoDigitYear)
          return this.setState({ dobNricError: true });
      }

      if (this.props.isPortIn) {
        this.props.checkSubscriptionInfo(userDetails, serviceNumber, authToken);
      } else {
        this.props.setUserDetails(userDetails);
        navigate('/confirm-details/');
      }
    } else {
      const firstInvalidField = Object.keys(errors).filter(
        e => errors[e].length !== 0
      )[0];
      const element = document.getElementById(firstInvalidField);
      if (element) {
        element.scrollIntoView();
        element.focus();
      }

      this.setState({ errors });
    }
  };

  useMyinfo = () => {
    // call myinfo api to check valid url
    this.props.useMyinfo(this.props.customerData);
  };

  resetLtvpCardType = () => {
    this.setState({
      ltvpCardType: LTVP_CARD_TYPES.PHYSICAL.value
    });
  };

  resetNationalityWhenIdPrefixNotMatchingWithNationality = idPrefix => {
    const isSelectedIdPrefixIsForForeignNationality = !isCitizenPrefix(
      idPrefix
    );
    const isCurrentNationalitySelectionIsSgCitizen =
      this.state.nationality === 'SG';

    // Foreigner should not be allowed to select 'Singapore Citizen' nationality option
    if (
      isSelectedIdPrefixIsForForeignNationality &&
      isCurrentNationalitySelectionIsSgCitizen
    ) {
      this.setState({ nationality: '' });
    }
  };

  clearFormDetails = () => {
    this.props.resetUserDetails();
    this.props.resetUploadImage();
    this.props.retrieveAddressClear();
    this.setState({
      firstName: '',
      lastName: '',
      title: '',
      gender: '',
      email: '',
      confirmEmail: '',
      contactNo: '',
      dateOfBirth: '',
      idPrefix: 's',
      idNo: '',
      idType: '',
      nationality: '',
      frontIdImage: '',
      backIdImage: '',
      errors: {},
      postalCode: '',
      myInfoDataRetrieved: false
    });
  };

  handleTextChange = (e, key) => {
    const errors = { ...this.state.errors };
    if (key === 'idPrefix') {
      if (this.state.idNo) {
        const error = checkNRIC(this.state.idNo, e.target.value);
        if (error) {
          errors.idNo = error;
        } else {
          delete errors.idNo;
        }
      }
      // reset idType
      this.setState({ idType: '' });

      this.resetLtvpCardType();

      this.resetNationalityWhenIdPrefixNotMatchingWithNationality(
        e.target.value
      );
    }

    this.setState({ [key]: e.target.value, errors });
  };

  handleAddressSelectionChange = e => {
    this.setState({
      selectedAddressIdx: e.target.value ? parseInt(e.target.value) : null,
      floorNumber: '',
      unitNumber: ''
    });
  };

  handleDropdownChange = (e, key) => {
    const ERROR_MAP = {
      floorNumber: 'floor number',
      unitNumber: 'unit number',
      title: 'title',
      idType: 'ID Type',
      gender: 'gender',
      nationality: 'nationality'
    };

    const { value } = e.target;
    const errors = { ...this.state.errors };
    const error = checkIsEmpty(value, ERROR_MAP[key]);
    if (error) {
      errors[key] = error;
    } else {
      delete errors[key];
    }

    let unitNumber = this.state.unitNumber;
    if (key === 'unitNumber') {
      unitNumber = e.target.value;
    } else if (key === 'floorNumber') {
      unitNumber = '';
    }
    this.setState({ [key]: e.target.value, errors, unitNumber });

    if (key === userDetailsFormFields.idType) {
      this.resetLtvpCardType();
    }
  };

  handleRetrieveAddressClick = () => {
    const { postalCode } = this.state;
    const errors = { ...this.state.errors };
    const error = checkPostalCode(postalCode);
    if (!error) {
      this.props.retrieveAddress(
        postalCode,
        this.props.authToken,
        this.props.serviceNumber,
        'billing'
      );
      delete errors['postalCode'];
    } else {
      errors.postalCode = error;
    }
    this.setState({
      errors,
      floorNumber: '',
      unitNumber: '',
      selectedAddressIdx: null,
      retrievedPostalCode: postalCode
    });
  };

  handleImageUpload = (e, key) => {
    const { serviceNumber } = this.props;

    if (e.target.files.length > 0) {
      this.props.uploadImage(
        e.target.files[0],
        key,
        this.props.authToken,
        serviceNumber
      );
    }
  };

  handleRadioInputChange = (e, key) => {
    this.setState({ [key]: e.target.value });
  };

  handleModalClose = ({ error } = {}) => {
    this.props.clearError('RETRIEVE_ADDRESS');
    this.props.setSubscriptionError(false);
    this.props.setPendingPortInError(false);
    this.props.setNumberNotFoundError(false);
    this.props.setBlacklistedCustomerError(false);
    this.props.setGenericError(false);
    this.props.setMyinfoError(false);
    this.props.retrieveAddressStatus(null);
    this.setState({ dobNricError: false });
    if (this.props.userDetails.pendingPortInError) {
      navigate(NUMBER_SELECTION_PAGE.RELATIVE);
    } else if (
      error === FORM_ERRORS.INCOMPLETE_MY_INFO_DETAILS_FOR_ESIM ||
      error === FORM_ERRORS.INCOMPLETE_MY_INFO_DETAILS_FOR_PSIM
    ) {
      this.clearFormDetails();
      window.open('https://www.singpass.gov.sg/main');
    }
  };

  handleDisablePaste = e => {
    e.preventDefault();
  };

  render() {
    const {
      firstName,
      lastName,
      title,
      gender,
      email,
      confirmEmail,
      contactNo,
      dateOfBirth,
      idPrefix,
      idNo,
      idType,
      ltvpCardType,
      nationality,
      postalCode,
      selectedAddressIdx,
      floorNumber,
      unitNumber,
      errors,
      dobNricError
    } = this.state;

    const {
      retrieveAddressLoading,
      retrieveAddressError,
      deliveryAddress,
      uploadedImages,
      userDetails,
      isSelfCollection,
      addressStatus,
      myinfoMode,
      selectedSimType,
      myInfoServiceAvailable
    } = this.props;

    const formattedBillingAddressList = this.getAddressList();
    const addressToRender = this.determineNewAddress();
    const disabled = myinfoMode === CITIZEN || myinfoMode === WORK_PASS;
    const formatPassExpiry = isDate(userDetails.passExpiryDate)
      ? userDetails.passExpiryDate
      : '';
    const isIncompletePassCheck =
      myinfoMode &&
      this.state.myInfoDataRetrieved &&
      incompletePassCheck({
        myinfoMode,
        idType,
        idNo,
        nationality,
        passStatus: userDetails.passStatus,
        passExpiryDate: userDetails.passExpiryDate,
        idPrefix: idPrefix,
        enableMID: hasMSupport,
        dateOfBirth,
        firstName
      });

    const notEligiblePassCheck =
      myinfoMode &&
      inEligiblePassCheck({
        myinfoMode,
        passStatus: userDetails.passStatus,
        passExpiryDate: userDetails.passExpiryDate,
        idType
      });

    const showUserDetailsForm = shouldShowUserDetailsForm(
      myinfoMode,
      myInfoServiceAvailable
    );

    return (
      <>
        <Heading level={4} secondary>
          It's a start of a great friendship!
        </Heading>
        <Heading level={2}>Tell us about you!</Heading>
        {myInfoServiceAvailable ? (
          <RetrieveMyInfoPanel
            myInfoMode={myinfoMode}
            onClearFormDetailsClick={this.clearFormDetails}
            onRetrieveMyInfoClick={this.useMyinfo}
          />
        ) : (
          <StyledRow>
            <Paragraph>
              Singpass is currently unavailable. Please fill in the form below
              to proceed with your order instead
            </Paragraph>
          </StyledRow>
        )}
        {showUserDetailsForm && (
          <>
            <UserDetailsFields
              firstName={firstName}
              lastName={lastName}
              title={title}
              gender={gender}
              email={email}
              confirmEmail={confirmEmail}
              contactNo={contactNo}
              dateOfBirth={dateOfBirth}
              idPrefix={idPrefix}
              idNo={idNo}
              idType={idType}
              ltvpCardType={ltvpCardType}
              nationality={nationality}
              deliveryAddress={deliveryAddress}
              postalCode={postalCode}
              retrieveAddressLoading={retrieveAddressLoading}
              formattedBillingAddressList={formattedBillingAddressList}
              selectedAddressIdx={selectedAddressIdx}
              addressToRender={addressToRender}
              floorNumber={floorNumber}
              unitNumber={unitNumber}
              errors={errors}
              uploadedImages={uploadedImages}
              onDropdownChange={this.handleDropdownChange}
              onTextChange={this.handleTextChange}
              onTextBlur={this.handleTextBlur}
              onImageUpload={this.handleImageUpload}
              onRadioInputChange={this.handleRadioInputChange}
              onRetrieveAddressClick={this.handleRetrieveAddressClick}
              onAddressSelectionChange={this.handleAddressSelectionChange}
              onRetrieveMyInfo={this.useMyinfo}
              isSelfCollection={isSelfCollection}
              disablePaste={this.handleDisablePaste}
              disabled={disabled}
              myinfoMode={myinfoMode}
              selectedSimType={selectedSimType}
              passStatus={userDetails.passStatus}
              passExpiryDate={formatPassExpiry}
              clearFormDetails={this.clearFormDetails}
              mIDSupport={hasMSupport}
            />

            {addressToRender.street && (
              <Row>
                <Column noGutter xs={12} sm={3} md={3}>
                  <NavControls
                    disableNextButton={
                      isIncompletePassCheck ||
                      (!hasMSupport && isMID(idPrefix)) ||
                      notEligiblePassCheck
                    }
                    nextButtonText="NEXT"
                    fullWidth
                    onNextButtonClick={this.handleNextButtonClick}
                    trackEventNextButton={{
                      click: {
                        event: this.props.myinfoMode
                          ? GTM_DATALAYER_EVENTS.MY_INFO_DATA_RETRIEVED
                          : GTM_DATALAYER_EVENTS.PERSONAL_DETAILS_ENTERED,
                        eventCategory: GTM_EVENT_CATEGORIES.CONTENT_CLICK,
                        eventAction: GTM_EVENT_ACTIONS.BUTTON_CLICK,
                        eventLabel: CTA_TEXT.NEXT
                      }
                    }}
                  />
                </Column>
              </Row>
            )}
          </>
        )}

        <UserDetailsErrorModal
          retrieveAddressError={retrieveAddressError}
          addressStatus={addressStatus}
          subscriptionError={userDetails.subscriptionError}
          pendingPortInError={userDetails.pendingPortInError}
          numberNotFoundError={userDetails.numberNotFoundError}
          blacklistedCustomerError={userDetails.blacklistedCustomerError}
          genericError={userDetails.genericError}
          myinfoError={userDetails.myinfoError}
          dobNricError={dobNricError}
          isIncompletePassCheck={isIncompletePassCheck}
          selectedSimType={selectedSimType}
          handleModalClose={this.handleModalClose}
        />
      </>
    );
  }
}

export const mapStateToProps = state => {
  return {
    billingAddressList: state.retrievedAddress.billingAddressList,
    addressStatus: state.retrievedAddress.addressStatus,
    retrieveAddressError: retrieveAddressErrorSelector(state),
    deliveryAddress: state.deliveryDetails.address,
    retrieveAddressLoading: retrieveAddressSelector(state),
    uploadedImages: state.uploadedImages,
    userDetails: state.userDetails,
    myinfoMode: state.userDetails.myinfoMode,
    hasSubmittedOnce: state.userDetails.hasSubmittedOnce,
    authToken: state.auth.token,
    serviceNumber: state.selectedNumber.number,
    isPortIn: state.selectedNumber.mode === 'portIn',
    selectedSimType: state.simTypeDetails.selectedSimType,
    myInfoServiceAvailable: state.simTypeDetails.myInfoServiceAvailable,
    isSelfCollection: state.selfCollection.isSelfCollection,
    customerData: {
      selectedNumber: state.selectedNumber,
      auth: state.auth,
      apigeeToken: state.apigeeToken,
      deliveryDates: state.deliveryDates,
      deliveryDetails: state.deliveryDetails,
      retrievedAddress: state.retrievedAddress,
      selfCollection: state.selfCollection,
      simTypeDetails: state.simTypeDetails
    },
    queryString: state.handleQueryString.queryString || {}
  };
};

/* istanbul ignore next */
export const mapDispatchToProps = dispatch => {
  const {
    retrieveAddress,
    retrieveAddressClear,
    resetUserDetails,
    resetUploadImage,
    setUserDetails,
    uploadImage,
    clearError,
    checkSubscriptionInfo,
    setSubscriptionError,
    setPendingPortInError,
    setNumberNotFoundError,
    setBlacklistedCustomerError,
    setGenericError,
    setMyinfoError,
    retrieveAddressStatus,
    useMyinfo,
    getMyinfoData,
    setSelectedSimType,
    rehydrateSelectedNumber,
    setAuthToken,
    rehydrateDeliveryDates,
    setDeliveryDetails,
    rehydrateRetrieveAddress,
    rehydrateSelfCollect,
    rehydrateSimTypeDetails,
    rehydrateQueryString,
    rehydrateApigeeToken,
    pushEventToDataLayer
  } = actions;

  return {
    retrieveAddress: (postalCode, authToken, serviceNumber, addressType) =>
      dispatch(
        retrieveAddress(postalCode, authToken, serviceNumber, addressType)
      ),
    retrieveAddressClear: () => dispatch(retrieveAddressClear()),
    resetUserDetails: () => dispatch(resetUserDetails()),
    resetUploadImage: () => dispatch(resetUploadImage()),
    setUserDetails: userDetails => dispatch(setUserDetails(userDetails)),
    uploadImage: (file, key, token, serviceNumber) =>
      dispatch(uploadImage(file, key, token, serviceNumber)),
    clearError: errorKey => dispatch(clearError(errorKey)),
    checkSubscriptionInfo: (userDetails, serviceNumber, authCode) =>
      dispatch(checkSubscriptionInfo(userDetails, serviceNumber, authCode)),
    setSubscriptionError: error => dispatch(setSubscriptionError(error)),
    setPendingPortInError: error => dispatch(setPendingPortInError(error)),
    setNumberNotFoundError: error => dispatch(setNumberNotFoundError(error)),
    setBlacklistedCustomerError: error =>
      dispatch(setBlacklistedCustomerError(error)),
    setGenericError: error => dispatch(setGenericError(error)),
    setMyinfoError: error => dispatch(setMyinfoError(error)),
    retrieveAddressStatus: status => dispatch(retrieveAddressStatus(status)),
    useMyinfo: customerInfo => dispatch(useMyinfo(customerInfo)),
    getMyinfoData: code => dispatch(getMyinfoData(code)),
    setSelectedSimType: simType => dispatch(setSelectedSimType(simType)),
    rehydrateSelectedNumber: data => dispatch(rehydrateSelectedNumber(data)),
    setAuthToken: data => dispatch(setAuthToken(data)),
    rehydrateDeliveryDates: data => dispatch(rehydrateDeliveryDates(data)),
    setDeliveryDetails: data => dispatch(setDeliveryDetails(data)),
    rehydrateRetrieveAddress: data => dispatch(rehydrateRetrieveAddress(data)),
    rehydrateSelfCollect: data => dispatch(rehydrateSelfCollect(data)),
    rehydrateSimTypeDetails: data => dispatch(rehydrateSimTypeDetails(data)),
    rehydrateQueryString: code => dispatch(rehydrateQueryString(code)),
    rehydrateApigeeToken: data => dispatch(rehydrateApigeeToken(data)),
    pushEventToDataLayer: data => dispatch(pushEventToDataLayer(data))
  };
};
const withRedirectHoc = withRedirect(InputUserDetails);
const connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(InputUserDetails);

export default withApiFailureModal(connected);
