import React from 'react';
import { Theme } from '@lux/components-gomo';
import styled from 'styled-components';
import myInfoSrc from '../../../assets/images/myinfo.png';
import { EmphasizedInfo } from '../../EmphasizedInfo';
import { RetrieveMyInfoButton } from '../../common';

const StyledContainer = styled.div`
  margin-bottom: ${Theme.spacing.small};
`;

export const DigitalPassVerification = ({ onRetrieveMyInfo }) => {
  return (
    <StyledContainer data-testid="ltvp-digital-pass-section">
      <EmphasizedInfo
        headingImage={myInfoSrc}
        description={
          'Continue verification of your digital pass with Singpass.'
        }
        ImageButton={RetrieveMyInfoButton}
        onButtonClick={onRetrieveMyInfo}
        buttonProps={{
          'data-testid': 'retrieve-myinfo-button',
          fullWidth: true,
          secondary: true
        }}
        highlightStyle={'secondary'}
      />
    </StyledContainer>
  );
};
