import { MYINFO } from '../constants/response_types.json';
import { PASSTYPES } from '../constants/identification_types.json';
import { isEmptyString, isMID } from './validation';
import { isDate, convertToDate } from './datetime';
import { isPast } from 'date-fns';

export const myInfoValidator = data => {
  const myinfoError = {};
  for (const [key, value] of Object.entries(data)) {
    //Check date
    if (key === 'dateOfBirth' || key === 'passExpiryDate') {
      myinfoError[key] = value && isDate(value) ? '' : MYINFO.MISSING_FIELD;
    } else {
      myinfoError[key] = !isEmptyString(value) ? '' : MYINFO.MISSING_FIELD;
    }
  }
  return myinfoError;
};

export const incompletePassCheck = ({
  myinfoMode,
  idType,
  idNo,
  nationality,
  passStatus,
  passExpiryDate,
  idPrefix = '',
  enableMID,
  dateOfBirth,
  firstName
}) => {
  const isCitizen = myinfoMode === PASSTYPES.CITIZEN;
  const isWorkPass = myinfoMode === PASSTYPES.WORKPASS;

  const noMTypeSupport = enableMID === false && isMID(idPrefix);
  // citizen will always have empty id type, pass status and pass expiry
  if (isCitizen && !isDate(dateOfBirth)) {
    return true;
  }

  if (
    isCitizen &&
    (!isEmptyString(passStatus) ||
      !isEmptyString(passExpiryDate) ||
      isEmptyString(firstName) ||
      isEmptyString(nationality))
  ) {
    return true;
  }

  // for workpass holders, idType, idNo, nationality, passStatus, pass expiry should not be blank
  if (
    isWorkPass &&
    (isEmptyString(idType) ||
      isEmptyString(idNo) ||
      isEmptyString(nationality) ||
      isEmptyString(passStatus) ||
      isEmptyString(passExpiryDate) ||
      isEmptyString(firstName) ||
      !isDate(dateOfBirth) ||
      noMTypeSupport)
  ) {
    return true;
  }

  return false;
};

// for workpass - check if passholders id type is valid or not expired
export const inEligiblePassCheck = ({
  myinfoMode,
  passStatus,
  passExpiryDate,
  idType
}) => {
  const invalidPass = PASSTYPES.INVALID;

  if (
    myinfoMode === PASSTYPES.CITIZEN ||
    isEmptyString(passStatus) ||
    isEmptyString(passExpiryDate) ||
    isEmptyString(idType)
  )
    return false;

  return (
    passStatus?.toUpperCase() !== 'LIVE' ||
    (passExpiryDate && isPast(convertToDate(passExpiryDate))) ||
    invalidPass.includes(idType)
  );
};
