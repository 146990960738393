import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { TextField } from '@lux/components-gomo';
import { FormItem } from '../common';
import { noop, remCalc } from '@lux/helpers';
import { Button } from '../../components/tracked-components';

const InlineFieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: ${p => remCalc(p.top)};
  column-gap: 5px;
`;

const InlineFormItem = styled.div`
  flex: 1;
  min-width: 170px;
`;

const InlineButton = styled.div`
  padding-top: 18px;

  &&& > button {
    min-width: 110px;
  }
`;

const RetrievePostalCode = props => {
  const {
    addressLabel,
    postalCode,
    error,
    retrieveAddressLoading,
    containerStyle,
    onTextChange,
    onTextBlur,
    onRetrieveAddressClick
  } = props;

  return (
    <Fragment>
      <InlineFieldContainer top={containerStyle.top}>
        <InlineFormItem>
          <FormItem label={addressLabel} errorMessage={error}>
            <TextField
              data-testid="testInputPostalCode"
              placeholder="6-digit postal code"
              type="tel"
              value={postalCode}
              onBlur={e => onTextBlur(e, 'postalCode')}
              onChange={e => onTextChange(e, 'postalCode')}
              maxLength={6}
            />
          </FormItem>
        </InlineFormItem>
        <InlineButton data-testid="testRetrieveButton">
          <Button
            data-testid="testRetrieveButton"
            secondary
            onClick={onRetrieveAddressClick}
            disabled={retrieveAddressLoading}
          >
            Retrieve
          </Button>
        </InlineButton>
      </InlineFieldContainer>
    </Fragment>
  );
};

RetrievePostalCode.defaultProps = {
  addressLabel: 'Address',
  postalCode: '',
  error: '',
  retrieveAddressLoading: false,
  containerStyle: {
    top: 32
  },
  onTextChange: noop,
  onTextBlur: noop,
  onRetrieveAddressClick: noop
};

RetrievePostalCode.propTypes = {
  /** Description of address */
  addressLabel: PropTypes.string,
  /** Postal Code */
  postalCode: PropTypes.string,
  /** Error string */
  error: PropTypes.string,
  /** If button is loading */
  retrieveAddressLoading: PropTypes.bool,
  /** Container style props */
  containerStyle: PropTypes.shape({
    /** Margin top */
    top: PropTypes.number
  }),
  /** onTextChange event handler */
  onTextChange: PropTypes.func,
  /** onTextBlur event handler */
  onTextBlur: PropTypes.func,
  /** onRetrieveAddressClick event handler */
  onRetrieveAddressClick: PropTypes.func
};

export default RetrievePostalCode;
