import React from 'react';
import { GenericModalContent } from '@lux/components-gomo';
import { noop } from '@lux/helpers';
import { Modal } from '../../../components/tracked-components';
import { getUserDetailsErrorModalData } from '../../../helpers/userDetailsForm';
import { GTM_DATALAYER_EVENTS } from '../../../constants/gtmDataLayerEvents';
import { getErrorPopupDataLayerVars } from '../../../helpers/datalayer';

export const UserDetailsErrorModal = ({
  retrieveAddressError,
  addressStatus,
  subscriptionError,
  pendingPortInError,
  numberNotFoundError,
  blacklistedCustomerError,
  genericError,
  myinfoError,
  dobNricError,
  isIncompletePassCheck,
  selectedSimType,
  handleModalClose
}) => {
  const { header, message, buttonText, error } = getUserDetailsErrorModalData({
    retrieveAddressError,
    addressStatus,
    subscriptionError,
    pendingPortInError,
    numberNotFoundError,
    blacklistedCustomerError,
    genericError,
    myinfoError,
    dobNricError,
    isIncompletePassCheck,
    selectedSimType
  });

  const onModalCloseAction = () => {
    handleModalClose && handleModalClose({ error });
  };

  const errorModalDataLayerVars = getErrorPopupDataLayerVars({
    event: GTM_DATALAYER_EVENTS.MY_INFO_DATA_RETRIEVED,
    errorName: message,
    eventLabel: buttonText
  });

  return (
    <>
      {message && (
        <Modal
          onModalClose={onModalCloseAction}
          onModalRender={noop}
          renderProp={({ onModalClose }) => (
            <GenericModalContent
              theme="hybrid"
              headerText={header}
              paragraphText={message}
              buttonText={buttonText}
              onModalClose={onModalClose}
            />
          )}
          trackEvent={{
            modalRender: errorModalDataLayerVars.render,
            modalClose: errorModalDataLayerVars.buttonClick
          }}
        />
      )}
    </>
  );
};
