import React from 'react';
import { Heading, Paragraph, Theme } from '@lux/components-gomo';
import styled from 'styled-components';

export const StyledSectionHeader = styled(Heading)`
  font-weight: ${Theme.fonts.weight.semiBold};
`;

const StyledSectionDescription = styled(Paragraph)`
  padding-bottom: 16px;
`;

export const TitleSection = ({ title, description }) => {
  return (
    <>
      <StyledSectionHeader level={6}>{title}</StyledSectionHeader>
      <StyledSectionDescription>{description}</StyledSectionDescription>
    </>
  );
};
